<template>
    <a @click.prevent="cargarExpediente(asignacion.id_servicio, asignacion)" href="#" class="dropdown-item"  v-for="asignacion in asignaciones" :key="asignacion.id_aviso" >
        <div v-if="asignacion.visto_mensaje_cache==0" data-toggle="tooltip" data-placement="top" v-tooltip="extractContent(asignacion.direccion_cliente)" style="display: flex;overflow-x:auto;  justify-content: space-between;">
            <span style="font-weight: bold; width: 100%;"><i :class="asignacion.class" :style="asignacion.style" :title="asignacion.tipo"></i><i :class="asignacion.class2" :style="asignacion.style2" :title="asignacion.tipo2" ></i> {{asignacion.codigo_servicio}} </span>
            <span style="font-weight: bold; width: 100%; text-align: center;" class="">{{ asignacion.nombre_tramitador }}</span> 
            <span style="font-weight: bold; width: 100%; text-align: end;" class="text-muted text-sm">{{asignacion.fecha_hora}}</span>
        </div>
        <div v-else data-toggle="tooltip" data-placement="top" v-tooltip="extractContent(asignacion.direccion_cliente)" style="display: flex;overflow-x:auto;  justify-content: space-between;">
            <span style="width: 100%;"><i :class="asignacion.class" :style="asignacion.style" :title="asignacion.tipo"></i><i :class="asignacion.class2" :style="asignacion.style2" :title="asignacion.tipo2" ></i> {{asignacion.codigo_servicio}} </span>
            <span style="width: 100%; text-align: center;" class="">{{ asignacion.nombre_tramitador }}</span> 
            <span style="width: 100%; text-align: end;" class="text-muted text-sm">{{asignacion.fecha_hora}}</span>
        </div>
    </a>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default({
    props:{
        asignaciones: Array
    },
    components: {
        
    },
    setup() {
        
    },
  
    methods: {
        async cargarExpediente(id,asignacion){
            if(asignacion.visto_mensaje_cache==0){
                const api = new PwgsApi();
                
                await api.put('avisos/revisar-avisos', {tipo:'asignacion', ids_avisos:[asignacion.id_aviso]})
            }
            this.$router.push({
                name: 'Servicio',
                params: {
                    id:id
                }
            });
        },
        extractContent(s) {
            var span = document.createElement('span');
            span.innerHTML = s;
            try {
                return decodeURIComponent(escape(span.textContent)) || decodeURIComponent(escape(span.innerText));
            }
            catch (error) {
                console.log(error);
                return s;

            }
        },
       
    }
})
</script>
